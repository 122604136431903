import React, { useEffect } from 'react';
import { GET_AGRO_STAT_URL, GET_AGRO_STAT_SHORT_URL, SET_AGRO_STAT_ACTION, SET_AGRO_STAT_SHORT_ACTION } from '../../config/constants.js'
import { store } from '../../config/store'
import Papa from 'papaparse'
import { connect } from 'react-redux'
import BasicTable from '../table/table'


function setAgroStat(stat, short) {
    if (typeof stat === 'undefined') {
      return {
        type: 'BAD',
        payload: {},
    };
    }
    if(short) {
      return {
        type: SET_AGRO_STAT_SHORT_ACTION,
        payload: {
            columns: stat.data[0],
            data: stat.data.slice(1),
        },
      }
    }
    else {
      return {
        type: SET_AGRO_STAT_ACTION,
        payload: {
            columns: stat.data[0],
            data: stat.data.slice(1),
        },
      }
    }
}

function getTable(columns, data) {
  if (typeof columns == "undefined" || typeof data == "undefined" || columns.length == 0) {
    return [[], []]
  }

  var processed_columns = []

  columns.forEach((x, i) => {
    processed_columns.push(
      {
        Header: x,
        accessor: x,
      }
    )
  })

  var processed_data = []


  data.forEach((x, i) => {
    if( x.length <= 2 ) {
      return
    }
    var row = {}
    columns.forEach((field, j) => {
      row[field] = x[j]
    })
    processed_data.push(row)
  });

  return [processed_columns, processed_data]
}

const AgroStat = props => {

  var agroStat = props.agroStat
  let [columns, data] = [[], []]
  if(props.showMode.short_data && !props.ownProps.showOnlyFullData)  {
    [columns, data] = getTable(props.agroStatShort.columns, props.agroStatShort.data)
  } else {
    [columns, data] = getTable(agroStat.columns, agroStat.data)
  }
  const fetchStat = async (url) => {
    return await fetch(url)
    .then(response=>response.text())
    .then(function(response) {
        var results = Papa.parse(response);
        return results
    })
    .catch(function(error) {  
        console.log('Request failed', error)  
    });
  }
  useEffect(() => {
    (async () => {
      let res = await fetchStat(GET_AGRO_STAT_URL);
      store.dispatch(setAgroStat(res, false))
      res = await fetchStat(GET_AGRO_STAT_SHORT_URL);
      store.dispatch(setAgroStat(res, true))
    })();
  }, []);

  return (
    <div style={{ display: props.auth ? "block" : "none" }}>
        <BasicTable columns={columns} data={data} mode={props.tableMode} tableMode={props.TableMode} />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
    return {
      ownProps: ownProps,
      ...state
    };
}

export default connect(mapStateToProps)(AgroStat);
