import { SET_AGRO_STAT_ACTION, SET_AGRO_STAT_SHORT_ACTION } from '../../config/constants'

const initialState = {
    columns: [],
    data: [],
}

export const agroStatReducer = (state=initialState, action) => {
    switch(action.type) {
        case SET_AGRO_STAT_ACTION:
            return {
                ...action.payload,
            }
        default:
            return state
    }
};

export const agroStatShortReducer = (state=initialState, action) => {
    switch(action.type) {
        case SET_AGRO_STAT_SHORT_ACTION:
            return {
                ...action.payload,
            }
        default:
            return state
    }
};