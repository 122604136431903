export const BACKEND_URL = 'https://agroprice.website';
export const GET_AGRO_STAT_METHOD = '/agrodata'
export const GET_AGRO_STAT_SHORT_METHOD = '/agrodata_short'
export const GET_AGRO_STAT_UPL_METHOD = '/upl'
export const GET_AGRO_STAT_URL  = BACKEND_URL + GET_AGRO_STAT_METHOD
export const GET_AGRO_STAT_SHORT_URL  = BACKEND_URL + GET_AGRO_STAT_SHORT_METHOD
export const POST_UPL_AGRO_STAT_URL  = BACKEND_URL + GET_AGRO_STAT_UPL_METHOD
export const CHECK_PASSWORD_METHOD = '/check_password'
export const CHECK_PASSWORD_URL  = BACKEND_URL + CHECK_PASSWORD_METHOD

export const SET_AGRO_STAT_ACTION = 'SET_AGRO_STAT_ACTION'
export const SET_AGRO_STAT_SHORT_ACTION = 'SET_AGRO_STAT_SHORT_ACTION'
export const SET_SHOW_MOD_ACTION = 'SET_SHOW_MOD_ACTION'
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS'