import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { CHECK_PASSWORD_URL } from "../../config/constants";
import { store } from '../../config/store';
import { SET_AUTH_STATUS } from '../../config/constants';
import { connect } from 'react-redux'

import "./styles.css";

function checkPassword(data) {
    console.log(data)
    if( data.password == "agroprice123" ) {
        store.dispatch(
            {
                type: SET_AUTH_STATUS,
                payload: true,
            }
        )
    }
    /* fetch(CHECK_PASSWORD_URL + `?password=${password}`)
        .then(res => res.json())
        .then(
        (result) => {
            store.dispatch(
                {
                    type: SET_AUTH_STATUS,
                    payload: {
                        ...result,
                    },
                }
            )
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
        }
    ) */
}

function PasswordCheckForm(props) {
  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = async data => {
    checkPassword(data)
  };

  return (
    <form
      style={{ display: !props.auth ? "block" : "none" }}
      onSubmit={e => e.preventDefault()}
      onClick={handleSubmit(onSubmit)}
    >
      <label>Password</label>
      <input {...register('password')}  name="password" type="password"/>

      <input type="submit" />
    </form>
  );
}

function mapStateToProps(state, ownProps) {
    return state;
}

export default connect(mapStateToProps)(PasswordCheckForm);


