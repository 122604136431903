import { createStore, combineReducers } from 'redux'
import { agroStatReducer, agroStatShortReducer } from '../components/agrostat/reducer'
import { showModeReducer } from '../components/menu/reducer'
import { composeWithDevTools } from "redux-devtools-extension";
import { authReducer } from '../../src/components/passwordForm/reducer';

const rootReducer = combineReducers({
    showMode: showModeReducer,
    agroStat: agroStatReducer,
    agroStatShort: agroStatShortReducer,
    auth: authReducer,
})

export const store = createStore(
    rootReducer,
    composeWithDevTools(),    
)