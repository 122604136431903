import React from "react";
import { useTable } from "react-table";

 // задаем объект перечисления
export const TableMode = { Read: "read-mode", AdminMode: "admin-mode" };

const styles = {
  table: {
    margin: "auto",
    marginTop: "2ex",
  },
  thead: {
    backgroundColor: "blue",
    padding: "10px",
    color: "white",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "550",
  },
  td: {
    padding: "10px",
    border: "dotted 1px black",
  },
  th: {
    padding: "10px",
  }
};


export default function BasicTable({ columns, data, mode }) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // Sends the needed props to your table.
    getTableBodyProps, // Sends needed props to your table body
    headerGroups, // Returns normalized header groups
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row in order to be displayed.
  } = useTable({
    columns,
    data,
  });

  
  return (
    <table style={styles.table} {...getTableProps()} id={mode === TableMode.AdminMode ? 'adminTable' : 'userTable'}>
      <thead style={styles.thead}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th style={styles.th} {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td contenteditable={mode === TableMode.AdminMode ? 'true' : 'false'} {...cell.getCellProps()} style={styles.td}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}