import { SET_AUTH_STATUS } from '../../config/constants'

const initialState = false

export const authReducer = (state=initialState, action) => {
    switch(action.type) {
        case SET_AUTH_STATUS:
            return {
                ...action.payload,
            }
        default:
            return state
    }
};