import './App.css';
import AgroStat from './components/agrostat/agrostat';
import Menu from './components/menu/menu';
import PasswordForm from './components/passwordForm/passwordForm';
import React from 'react';
import BasicTable, { TableMode } from './components/table/table'
import AdminUpdateTableButton from './components/adminUpdateTableButton/adminUpdateTableButton'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams
  } from "react-router-dom";

function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="" element={
                        <React.Fragment>
                            <div className="App">
                                <Menu />
                                <PasswordForm />
                                <AgroStat tableMode={ TableMode.Read } />
                            </div>
                        </React.Fragment>
                    } />
                    <Route path="/admin" element={
                        <React.Fragment>
                            <Menu disableModeButton={true}/>
                            <PasswordForm />
                            <AgroStat tableMode={ TableMode.AdminMode } showOnlyFullData={true}/>
                            <AdminUpdateTableButton />
                        </React.Fragment>
                           
                    } />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
