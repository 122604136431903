import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { store } from '../../config/store'
import { SET_SHOW_MOD_ACTION } from '../../config/constants'
import { connect } from 'react-redux'
import { POST_UPL_AGRO_STAT_URL } from '../../config/constants.js'
import { useState } from 'react';

var tableToCsv = require('node-table-to-csv');

function UpdateTableDataButton(props) {

	const [passwd, setPasswd] = useState("");

	function handleSubmit(e) {
		e.preventDefault()
		let adminTable = document.getElementById("adminTable");
		let csv = tableToCsv(adminTable.outerHTML)
		const requestOptions = {
			method: 'PUT',
			headers: { 'Authorization': `Bearer ${passwd}` },
			body: csv.replaceAll('"',''),
		};
		fetch(POST_UPL_AGRO_STAT_URL, requestOptions).then((response) => {
			if (response.ok) {
				return response.json();
			}
			throw new Error('Something went wrong');
		})
		.catch((error) => {
			console.log(error)
		});
	}

	function handleChange(event) {
		event.preventDefault()
		setPasswd(event.target.value)
  }

  return (
		<form onSubmit={handleSubmit} style={{ display: props.auth ? "block" : "none" }}>
        <label>
        	Пароль для обновления:
          <input type="password" value={passwd} onChange={handleChange} />
        </label>
        <input type="submit" value="Обновить данные" />
    </form>
  );
}

function mapStateToProps(state, ownProps) {
  return state;
}

export default connect(mapStateToProps)(UpdateTableDataButton);
