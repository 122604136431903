import { SET_SHOW_MOD_ACTION } from '../../config/constants'

const initialState = {
    short_data: true
}

export const showModeReducer = (state=initialState, action) => {
    switch(action.type) {
        case SET_SHOW_MOD_ACTION:
            return {
                ...action.payload,
            }
        default:
            return state
    }
};